import { DialogController }    from 'aurelia-dialog';
import { bindable, inject }    from 'aurelia-framework';
import { BaseListViewModel }   from 'base-list-view-model';
import { BulletinsRepository } from 'modules/management/specifications/bulletins/services/repository';
import { AppContainer }        from 'resources/services/app-container';

@inject(AppContainer, BulletinsRepository, DialogController)
export class BulletinSpecificationsModal extends BaseListViewModel {

    listingId = 'management-specifications-listing-bulletin-specfications-modal-listing';

    @bindable headerTitle    = 'listing.management.specifications.bulletin-specifications';
    @bindable newRecordRoute = null;
    @bindable repository;
    @bindable datatable;

    @bindable alert = {};

    @bindable settings = {
        title:   'form.title.specifications',
        size:    'modal-lg',
        buttons: false,
    };

    fullData = [];

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param dialogController
     */
    constructor(appContainer, repository, dialogController) {
        super(appContainer);

        this.repository       = repository;
        this.dialogController = dialogController;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'management.specifications.specifications.manage',
            'management.specifications.specifications.view',
            'management.specifications.bulletins.manage',
            'management.specifications.bulletins.view',
        ]);
    }

    /**
     * Handles activate event
     */
    async activate(params) {
        super.activate();

        this.bulletin = params;

        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:      {
                search:          (criteria) => this.searchSpecifications(criteria),
                destroy:         null,
                destroySelected: false,
            },
            edit:            null,
            destroy:         null,
            destroySelected: false,
            options:         [],
            buttons:         [],
            selectable:      false,
            actions:         [],
            sorting:         {
                column:    0,
                direction: 'asc',
            },
            columns:         [
                {
                    data:  'specification_concession',
                    name:  'concession_translations.description',
                    title: 'form.field.concession',
                },
                {
                    data:  'specification_number',
                    name:  'specifications.number',
                    title: 'form.field.number',
                },
                {
                    data:  'edition_number',
                    name:  'specification_revisions.edition_number',
                    title: 'form.field.edition-number',
                },
                {
                    data:  'revision_number',
                    name:  'specification_revisions.revision_number',
                    title: 'form.field.revision-number',
                },
                {
                    data:            'effective_date',
                    name:            'specification_revisions.effective_date',
                    title:           'form.field.effective-date',
                    valueConverters: [
                        {
                            name:   'dateTimeFormat',
                            format: 'YYYY-MM-DD',
                        },
                    ],
                },
                {
                    data:  'code',
                    name:  'specification_revisions.code',
                    title: 'form.field.code',
                },
                {
                    data:    'created_by',
                    name:    'users.name',
                    title:   'form.field.created-by',
                    display: false,
                },
                {
                    data:            'created_at',
                    name:            'specification_revisions.created_at',
                    title:           'form.field.created-at',
                    display:         false,
                    valueConverters: [
                        {
                            name: 'dateTimeFormat',
                        },
                    ],
                },
            ],
        };
    }

    /**
     * Performs a search given some criteria
     *
     * @param criteria
     *
     * @returns {*}
     */
    searchSpecifications(criteria) {
        return this.repository.searchSpecifications(this.bulletin, criteria);
    }

    /**
     * Cancels the dialog
     *
     * @return {*}
     */
    cancel() {
        return this.dialogController.cancel();
    }
}
